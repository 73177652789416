import EventTicket from "@/types/EventTicket";
import Event from "@/types/Event";
import moment from "moment";
import { toastController } from "@ionic/vue";
import { checkmark, close } from "ionicons/icons";

const osPercentage = 0.05;
const osFlatRate = 1.25;
const taxPercentage = 0.07;
const spacPercentage = 0.05;
const osServicePercentage = 0.07;

export default function compGeneralAppUtils() {
  const eventCategories = [
    {
      label: "Hoy",
      value: "today",
    },
    {
      label: "Turismo & Cultura",
      value: "tourism",
    },
    {
      label: "Familia",
      value: "family",
    },
    {
      label: "Deportes",
      value: "sports",
    },
    {
      label: "Entretenimiento",
      value: "entertainment",
    },
    {
      label: "Comercial",
      value: "comercial",
    },
  ];
  const eventTypes = [
    { value: "public", label: "Público" },
    { value: "private", label: "Privado" },
  ];
  const eventSpaces = [
    { value: "basic", label: "Básico" },
    { value: "exclusive", label: "Destacado" },
    { value: "hot", label: "Exclusivo" },
  ];

  function dateFormatting(
    date: string | undefined,
    format = "D MMMM YYYY",
    currFormat = "YYYY/MM/DD",
    locale = "es"
  ) {
    if (!date) return "";
    return moment(date, currFormat).locale(locale).format(format);
  }

  function timeStampFormat(date: any) {
    return moment(date).locale("es").format("D MMMM YYYY [-] hh:mm a");
  }

  function currencyFormat(amount: any) {
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return USDollar.format(amount);
  }

  function daysUntil(date: string | undefined) {
    if (!date) return "";
    const d = moment(date);
    return moment().locale("es").to(d, true);
  }

  function cleanHTML(html: string | undefined) {
    if (!html) return "";
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  function substringText(text: string, length: number) {
    if (text.length <= length) return text;
    else return `${text.substring(0, length).trim()}...`;
  }

  function formatCurrencyAmount(amount: number | string) {
    let m: number;
    if (typeof amount == "string") m = parseFloat(amount);
    else m = amount;
    return Math.round((m + Number.EPSILON) * 100) / 100;
  }

  function calculateSubTotal(txs: EventTicket[]) {
    let sub = 0;
    txs.forEach((tx) => {
      sub += tx.price * tx.amountToBuy;
    });
    return sub;
  }

  function calculateOSFee(txs: EventTicket[]) {
    let os = 0;
    txs.forEach((tx) => {
      if (tx.amountToBuy > 0)
        os += (tx.price * osPercentage + osFlatRate) * tx.amountToBuy;
    });
    return os;
  }

  function calculateTaxes(txs: EventTicket[]) {
    let taxes = 0;
    txs.forEach((tx) => {
      if (tx.amountToBuy > 0)
        taxes += tx.price * taxPercentage * tx.amountToBuy;
    });
    return taxes;
  }

  function calculateSpac(txs: EventTicket[]) {
    let spac = 0;
    txs.forEach((tx) => {
      if (tx.amountToBuy > 0)
        spac += tx.price * spacPercentage * tx.amountToBuy;
    });
    return spac;
  }

  function calculateTotalCharge(
    txs: EventTicket[],
    chargeTaxes: boolean,
    chargeSpac: boolean
  ) {
    const sub = calculateSubTotal(txs);
    const os = calculateOSFee(txs);
    const taxes = calculateTaxes(txs);
    const spac = calculateSpac(txs);
    let total = sub + os;
    if (chargeTaxes) total += taxes;
    if (chargeSpac) total += spac;
    return total;
  }

  async function showMessage(
    type: "success" | "danger",
    message: string,
    duration = 3000
  ) {
    let icon;
    if (type == "success") icon = checkmark;
    if (type == "danger") icon = close;
    const toast = await toastController.create({
      message: message,
      duration: duration,
      position: "top",
      color: type,
      icon: icon,
    });
    await toast.present();
  }

  function formatEventType(value: string) {
    return eventTypes.find((types) => types.value == value);
  }

  function formatEventSpace(space: string) {
    return eventSpaces.find((types) => types.value == space);
  }

  function openTermsPage(path: string) {
    const url = "https://onespotlive.com";
    window.open(url + path, "_blank")?.focus();
  }

  function isEventToday(event: Event) {
    let isToday = false;
    const today = moment();
    event.dates.forEach((date) => {
      const momentDate = moment(date.startDate?.split("T")[0] + "T00:00:00");
      if (today.isSameOrAfter(momentDate)) isToday = true;
    });
    return isToday;
  }

  function returnOSFeeValues() {
    return {
      perc: osPercentage,
      flat: osFlatRate,
      serv: osServicePercentage,
    };
  }

  return {
    eventCategories,
    dateFormatting,
    daysUntil,
    cleanHTML,
    substringText,
    formatCurrencyAmount,
    calculateSubTotal,
    calculateOSFee,
    calculateTaxes,
    calculateSpac,
    calculateTotalCharge,
    showMessage,
    formatEventType,
    formatEventSpace,
    timeStampFormat,
    currencyFormat,
    returnOSFeeValues,
    isEventToday,
    openTermsPage,
  };
}
